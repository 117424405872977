import { Chip } from '@mui/material'

const StatusLabel = (data: any) => {
  const { total, status_type, status_label, status_date_validity, chat_date_close, chat_date_start, chat_employee_id } =
    data.data

  let color: any = 'primary'
  let label = ''
  let title = ''
  let type = status_type

  if (chat_date_start > 0 && chat_date_close === 0 && chat_employee_id === 0) type = 0
  else if (chat_date_close > 0) type = 99

  switch (type) {
    case 0:
      color = 'success'
      label = total === 1 ? 'Novo Lead' : 'Novo Chat'
      break
    case 1:
      color = 'info'
      label = status_label
      break
    case 2:
      color = 'warning'
      label = status_label
      title = `Até ${status_date_validity}`
      break
    case 3:
      color = 'error'
      label = status_label
      title = `Em ${status_date_validity}`
      break
    case 99:
      color = 'default'
      label = 'Encerrado'
      break
    default:
      color = 'info'
      label = 'Atendendo'
      break
  }

  if (label === '') return null

  return (
    <span title={title}>
      <Chip
        sx={{
          borderRadius: '4px',
          fontSize: 10,
          height: 'auto',
          lineHeight: '1',
          marginRight: '4px',
          span: {
            padding: '2px 4px',
          },
        }}
        label={label}
        size="small"
        color={color}
      />
    </span>
  )
}

export default StatusLabel
