import theme from '../../styles/themes'
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { FC, useEffect, useState } from 'react'
import { request } from '../../utils/fetcher'
import { BarChart } from '@mui/x-charts'
import ForumIcon from '@mui/icons-material/Forum'
import PersonIcon from '@mui/icons-material/Person'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import LastPageIcon from '@mui/icons-material/LastPage'
import ShortcutIcon from '@mui/icons-material/Shortcut'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import StatusLabel from '../../components/StatusLabel'

interface TablePaginationActionsProps {
  count: number
  page: number
  rowsPerPage: number
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme()
  const { count, page, rowsPerPage, onPageChange } = props

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0)
  }

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  )
}

const Chart: FC<{ title?: string; type: string; filters: any }> = ({ title, type, filters }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [data, setData] = useState<any>(null)
  const [tablePage, setTablePage] = useState(0)
  const [tableRowsPerPage, setTableRowsPerPage] = useState(10)
  const [seeIgnoreds, setSeeIgnoreds] = useState(false)

  useEffect(() => {
    getChartData(type, filters)
  }, [])

  const getChartData = async (chart: string, filters: any) => {
    setLoading(true)
    const resp = await request('company/graphs', { type: chart, filters })
    setLoading(false)
    if (resp.success && resp.chart !== null) setData(resp.chart.data)
  }

  const handleIgnore = async (chatId: number) => {
    setData(null)
    setLoading(true)
    await request('company/ignore-chat', { chat_id: chatId, ignore: seeIgnoreds ? 0 : 1 })
    getChartData(type, filters)
  }

  const handleToggleIgnoreds = () => {
    setSeeIgnoreds(!seeIgnoreds)
    setTableRowsPerPage(10)
    setTablePage(0)
  }

  const monthFormatter = (value: string) => {
    switch (value) {
      case '01':
        return 'Jan'
      case '02':
        return 'Fev'
      case '03':
        return 'Mar'
      case '04':
        return 'Abr'
      case '05':
        return 'Mai'
      case '06':
        return 'Jun'
      case '07':
        return 'Jul'
      case '08':
        return 'Ago'
      case '09':
        return 'Set'
      case '10':
        return 'Out'
      case '11':
        return 'Nov'
      case '12':
        return 'Dez'
      default:
        return ''
    }
  }

  const handleChangeTablePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setTablePage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setTableRowsPerPage(parseInt(event.target.value, 10))
    setTablePage(0)
  }

  const getChartComponent = (title: string | undefined, type: string, data: any) => {
    switch (type) {
      case 'chatsActiveVsInactive':
        return (
          <Paper sx={{ padding: '20px 10px', flex: 1 }}>
            {title && <Typography variant="h6">{title}</Typography>}
            {/* <div
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50px', gap: '16px' }}
            >
              <div style={{ display: 'flex' }}>
                <div style={{ backgroundColor: '#0288d1', display: 'block', width: '8px', height: '16px' }}></div>
                <div style={{ backgroundColor: '#0288d1', display: 'block', width: '8px', height: '16px' }}></div>
                &nbsp;&nbsp; <span>Aberto</span>
              </div>
              <div style={{ display: 'flex' }}>
                <div style={{ backgroundColor: '#d32f2f', display: 'block', width: '8px', height: '16px' }}></div>
                <div style={{ backgroundColor: '#d32f2f', display: 'block', width: '8px', height: '16px' }}></div>
                &nbsp;&nbsp; <span>Fechado</span>
              </div>
            </div> */}
            <BarChart
              dataset={Object.keys(data).map((key) => data[key])}
              series={[
                {
                  color: '#0288d1',
                  label: 'Iniciado pela Empresa (Em Atendimento)',
                  dataKey: 'NewCompanyChats_Active',
                  stack: 'active',
                },
                {
                  color: '#0288d1',
                  label: 'Iniciado pelo Cliente (Em Atendimento)',
                  dataKey: 'NewClientChats_Active',
                  stack: 'active',
                },
                {
                  color: '#d32f2f',
                  label: 'Iniciado pelo Cliente (Finalizado)',
                  dataKey: 'NewClientChats_Closed',
                  stack: 'closed',
                },
                {
                  color: '#d32f2f',
                  label: 'Iniciado pela Empresa (Finalizado)',
                  dataKey: 'NewCompanyChats_Closed',
                  stack: 'closed',
                },
              ]}
              xAxis={[{ scaleType: 'band', dataKey: 'month', valueFormatter: monthFormatter }]}
              slotProps={{ legend: { hidden: true } }}
              height={250}
            />
          </Paper>
        )
      case 'messagesHeatMap':
        const maxValue = Math.max(...data.map((obj: any) => obj.countAllMessages))

        const getColor = (value: number) => {
          if (value === 0) return '#f8f8f8'
          const intensity = Math.round((value / maxValue) * 255)
          return `rgb(${255 - intensity}, ${255 - intensity}, 255)`
        }

        const getContrastingColor = (backgroundColor: string) => {
          const calculateLuminance = (r: number, g: number, b: number) => {
            const adjust = (c: number) => (c <= 0.03928 ? c / 12.92 : ((c + 0.055) / 1.055) ** 2.4)
            const [rAdj, gAdj, bAdj] = [r, g, b].map((c) => adjust(c / 255))
            return 0.2126 * rAdj + 0.7152 * gAdj + 0.0722 * bAdj
          }

          const parseRGB = (rgbString: string) => rgbString.match(/\d+/g)?.map(Number)

          const [r, g, b] = parseRGB(backgroundColor) || [0, 0, 0]
          const backgroundLuminance = calculateLuminance(r, g, b)

          const blackLuminance = 0
          const grayLuminance = calculateLuminance(128, 128, 128)

          const contrastWithBlack = (backgroundLuminance + 0.05) / (blackLuminance + 0.05)
          const contrastWithGray = (backgroundLuminance + 0.05) / (grayLuminance + 0.05)

          return contrastWithBlack >= 4.5 ? 'black' : contrastWithGray >= 4.5 ? 'gray' : 'white'
        }

        const grid = Array.from({ length: 7 }, () => Array.from({ length: 24 }, () => 0))
        data.forEach(
          ({ weekDay, hourDay, countAllMessages }: { weekDay: string; hourDay: string; countAllMessages: number }) => {
            grid[parseInt(weekDay)][parseInt(hourDay)] = countAllMessages
          }
        )

        const getWeekDayLabel = (day: number) => {
          switch (day) {
            case 0:
              return 'Dom'
            case 1:
              return 'Seg'
            case 2:
              return 'Ter'
            case 3:
              return 'Qua'
            case 4:
              return 'Qui'
            case 5:
              return 'Sex'
            case 6:
              return 'Sab'
            default:
              return ''
          }
        }

        return (
          <>
            {title && <Typography variant="h6">{title}</Typography>}

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '2px',
                width: '100%',
                margin: '15px 0 40px 0',
              }}
            >
              {grid.map((day, weekIndex) => (
                <div key={`day-${weekIndex}`} style={{ display: 'flex', gap: '2px' }}>
                  <div
                    style={{ width: '30px', fontSize: '11px', height: '25px', display: 'flex', alignItems: 'center' }}
                  >
                    {getWeekDayLabel(weekIndex)}
                  </div>

                  {day.map((count, hourIndex) => {
                    const bgColor = getColor(count)
                    return (
                      <div
                        key={`hour-${hourIndex}`}
                        style={{
                          backgroundColor: bgColor,
                          width: 'calc(100%/24)',
                          height: '25px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          fontSize: '11px',
                          color: getContrastingColor(bgColor),
                          borderRadius: '4px',
                        }}
                        title={`${getWeekDayLabel(weekIndex)} as ${hourIndex}h: ${count} mensagens`}
                      >
                        {count > 0 ? count : ''}
                      </div>
                    )
                  })}
                </div>
              ))}
              <div style={{ display: 'flex', alignItems: 'center', gap: '2px', marginLeft: '30px' }}>
                {[
                  '00',
                  '01',
                  '02',
                  '03',
                  '04',
                  '05',
                  '06',
                  '07',
                  '08',
                  '09',
                  '10',
                  '11',
                  '12',
                  '13',
                  '14',
                  '15',
                  '16',
                  '17',
                  '18',
                  '19',
                  '20',
                  '21',
                  '22',
                  '23',
                ].map((hour: string) => (
                  <div
                    key={`hour-${hour}`}
                    style={{
                      width: 'calc(100%/24)',
                      height: '25px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '11px',
                    }}
                  >
                    {hour}h
                  </div>
                ))}
              </div>
            </div>
          </>
        )
      case 'chatDurationAverageTime':
        // const colors: { [key: string]: string } = {
        //   '1 hour': '#FF7043',
        //   '12 hours': '#FF7043',
        //   '24 hours': '#FF7043',
        //   '72 hours': '#FF7043',
        //   '7 days': '#FF7043',
        //   '15 days': '#FF7043',
        //   'More than 15 days': '#FF7043',
        // }
        // return (
        //   <Paper sx={{ padding: '20px 10px', width: '45%' }}>
        //     {title && <Typography variant="h6">{title}</Typography>}
        //     <PieChart
        //       height={300}
        //       series={[
        //         {
        //           data: Object.keys(data).map((key) => {
        //             return {
        //               color: colors[data[key].label],
        //               ...data[key],
        //             }
        //           }),
        //           arcLabel: (params) => `${params.value}`,
        //         },
        //       ]}
        //     />
        //   </Paper>
        // )

        return (
          <Paper sx={{ padding: '20px 10px', flex: 1 }}>
            {title && <Typography variant="h6">{title}</Typography>}
            <BarChart
              dataset={Object.keys(data).map((key) => data[key])}
              series={[
                {
                  color: '#651FFF',
                  label: 'Chats',
                  dataKey: 'value',
                },
              ]}
              xAxis={[{ scaleType: 'band', dataKey: 'label' }]}
              slotProps={{ legend: { hidden: true } }}
              height={250}
            />
          </Paper>
        )
      case 'generalData':
        return (
          <div style={{ width: '100%', display: 'flex', gap: '100px', padding: '30px 0', marginBottom: '20px' }}>
            <div>
              <div style={{ display: 'flex', gap: '20px' }}>
                <span title="Clientes">
                  <PersonIcon sx={{ fontSize: 64 }} color="primary" />
                </span>
                <div style={{ paddingRight: '20px', borderRight: `1px solid ${theme.colors.primary}` }}>
                  <Typography variant="overline" sx={{ display: 'block' }}>
                    ABERTOS
                  </Typography>
                  <Typography variant="h5" color={'primary'}>
                    {data.NewActiveChats_Total}
                  </Typography>
                </div>
                <div>
                  <Typography variant="overline" sx={{ display: 'block' }}>
                    FECHADOS
                  </Typography>
                  <Typography variant="h5" color={'primary'}>
                    {data.NewClosedChats_Total}
                  </Typography>
                </div>
              </div>
            </div>

            <div>
              <div style={{ display: 'flex', gap: '20px' }}>
                <span title="Mensagens">
                  <ForumIcon sx={{ fontSize: 64 }} color="primary" />
                </span>
                <div style={{ paddingRight: '20px', borderRight: `1px solid ${theme.colors.primary}` }}>
                  <Typography variant="overline" sx={{ display: 'block' }}>
                    ENVIADAS
                  </Typography>
                  <Typography variant="h5" color={'primary'}>
                    {data.SentMessages}
                  </Typography>
                </div>
                <div>
                  <Typography variant="overline" sx={{ display: 'block' }}>
                    RECEBIDAS
                  </Typography>
                  <Typography variant="h5" color={'primary'}>
                    {data.ReceivedMessages}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        )
      case 'clientsWithoutAnswer':
        const maxDiff = Math.max(...data.map((obj: any) => obj.days_diff))

        const getDiffColor = (value: number) => {
          if (value === 0) return '#f8f8f8'
          const intensity = Math.round((value / maxDiff) * 255)
          return `rgb(${255 - intensity}, ${255 - intensity}, 255)`
        }

        const getContrastingTextColor = (backgroundColor: string) => {
          const calculateLuminance = (r: number, g: number, b: number) => {
            const adjust = (c: number) => (c <= 0.03928 ? c / 12.92 : ((c + 0.055) / 1.055) ** 2.4)
            const [rAdj, gAdj, bAdj] = [r, g, b].map((c) => adjust(c / 255))
            return 0.2126 * rAdj + 0.7152 * gAdj + 0.0722 * bAdj
          }

          const parseRGB = (rgbString: string) => rgbString.match(/\d+/g)?.map(Number)

          const [r, g, b] = parseRGB(backgroundColor) || [0, 0, 0]
          const backgroundLuminance = calculateLuminance(r, g, b)

          const blackLuminance = 0
          const grayLuminance = calculateLuminance(128, 128, 128)

          const contrastWithBlack = (backgroundLuminance + 0.05) / (blackLuminance + 0.05)
          const contrastWithGray = (backgroundLuminance + 0.05) / (grayLuminance + 0.05)

          return contrastWithBlack >= 4.5 ? 'black' : contrastWithGray >= 4.5 ? 'gray' : 'white'
        }

        const items = data.filter((row: any) => row.ignore_on_charts === (seeIgnoreds ? 1 : 0))

        return (
          <div style={{ width: '100%', paddingTop: '20px' }}>
            <div style={{ display: 'flex', gap: '20px' }}>
              {title && <Typography variant="h6">{title}</Typography>}

              <Button onClick={() => handleToggleIgnoreds()} size="small" sx={{ textTransform: 'initial' }}>
                {!seeIgnoreds ? 'Ver Ignorados' : 'Ver Não Ignorados'} &nbsp;{' '}
                <RemoveCircleOutlineIcon style={{ fontSize: '14px' }} />
              </Button>
            </div>

            <TableContainer component={Paper} sx={{ marginTop: '20px' }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bolder' }}>Dias s/ resposta</TableCell>
                    <TableCell sx={{ fontWeight: 'bolder' }}>Cliente</TableCell>
                    <TableCell sx={{ fontWeight: 'bolder' }}>Status</TableCell>
                    <TableCell sx={{ fontWeight: 'bolder' }}>Mensagem</TableCell>
                    <TableCell sx={{ fontWeight: 'bolder' }}>Atribuído</TableCell>
                    <TableCell sx={{ fontWeight: 'bolder' }}>Última Mensagem</TableCell>
                    <TableCell sx={{ fontWeight: 'bolder' }}>Ignorar</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items
                    .slice(tablePage * tableRowsPerPage, tablePage * tableRowsPerPage + tableRowsPerPage)
                    .map((row: any) => {
                      const bgColor = getDiffColor(row.days_diff)

                      return (
                        <TableRow key={row.id} hover={true}>
                          <TableCell>
                            <span
                              style={{
                                backgroundColor: bgColor,
                                padding: '2px 10px',
                                borderRadius: '4px',
                                color: getContrastingTextColor(bgColor),
                              }}
                            >
                              {row.days_diff}
                            </span>
                          </TableCell>
                          <TableCell
                            style={{
                              maxWidth: '250px',
                              overflowX: 'clip',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            <a
                              href={`./chat/${row.id}`}
                              style={{ color: theme.colors.primary }}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {row.client_name}{' '}
                              {parseInt(row.client_name) !== row.client_phone ? `(${row.client_phone})` : ''}{' '}
                              <ShortcutIcon style={{ fontSize: '11px' }} />
                            </a>
                          </TableCell>
                          <TableCell>
                            <StatusLabel data={row} />
                          </TableCell>
                          <TableCell
                            style={{
                              maxWidth: '250px',
                              overflowX: 'clip',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {row.last_message}
                          </TableCell>
                          <TableCell>
                            {row.chat_employee_id > 0
                              ? row.employee_name
                              : row.chat_department_id > 0
                              ? row.departments_name
                              : '-'}
                          </TableCell>
                          <TableCell>{row.last_message_add}</TableCell>
                          <TableCell>
                            <Button
                              size="small"
                              onClick={() => handleIgnore(row.chat_id)}
                              title={seeIgnoreds ? 'Remover dos Ignorados' : 'Ignorar'}
                            >
                              <RemoveCircleOutlineIcon style={{ fontSize: '16px' }} />
                            </Button>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[10, 20, 50, 100]}
                      count={items.length}
                      rowsPerPage={tableRowsPerPage}
                      page={tablePage}
                      labelRowsPerPage={'Linhas'}
                      slotProps={{
                        select: {
                          inputProps: {
                            'aria-label': 'linhas',
                          },
                          native: true,
                        },
                      }}
                      onPageChange={handleChangeTablePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
        )
      default:
        return null
    }
  }

  const getLoaderComponent = (type: string) => {
    let customStyle: any = {
      minWidth: '100%',
      minHeight: 150,
    }

    if (type === 'chatsActiveVsInactive' || type === 'chatDurationAverageTime') {
      customStyle = {
        minWidth: 500,
        minHeight: 300,
      }
    }

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          ...customStyle,
        }}
      >
        <CircularProgress />
      </div>
    )
  }

  return (
    <>
      {loading && getLoaderComponent(type)}
      {data && getChartComponent(title, type, data)}
    </>
  )
}

export default Chart
