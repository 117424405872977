import { FC, useEffect, useState } from 'react'
import Layout from '../../components/Layout'
import {
  Alert,
  Box,
  Button,
  Fab,
  FormControl,
  Grid,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material'
import theme from '../../styles/themes'
import ApartmentIcon from '@mui/icons-material/Apartment'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { request } from '../../utils/fetcher'
import EditComapany from './edit'
import EditIcon from '@mui/icons-material/Edit'

const CompanyManager: FC = () => {
  const [loading, setLoading] = useState(false)
  const [companies, setCompanies] = useState([])
  const [company, setCompany] = useState<any>(null)
  const [search, setSearch] = useState("")
  const [plans, setPlans] = useState([])
  const [message, setMessage] = useState({ type: '', content: '' })

  const loadAllCompanies = async () => {
    setLoading(true)
    const data = await request(`company/get-all-companies/`, { search: search.trim() })

    if (data.success && data.companies.length > 0) {
      setCompanies(data.companies)
      setPlans(data.plans)
    }

    setLoading(false)
  }

  useEffect(() => {
    loadAllCompanies()
  }, [])

  useEffect(() => {
    loadAllCompanies()
  }, [search])

  const handleEdit = (companyData: any) => {
    setCompany({ ...companyData })
  }

  const handleNewCompany = () => {
    setCompany({ company_id: 0 })
  }

  const onChange = (e: any) => {
    setSearch(e.target.value)
  }

  return (
    <div
      style={{
        overflow: 'auto',
      }}
    >
      <Layout>
        <Box
          sx={{
            maxWidth: '100%',
            margin: '20px',
            paddingBottom: '50px'
          }}
        >
          <h4
            style={{
              padding: '0 20px',
              margin: '0 0 20px 0',
              fontSize: 16,
              fontWeight: '400',
              color: theme.colors.primary,
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <ApartmentIcon /> Empresas
          </h4>

          {company !== null && (
            <EditComapany 
              data={company} 
              plans={plans} 
              handleClose={() => {
                setCompany(null)
                loadAllCompanies()
              }} 
              handleMessage={setMessage}
              />
            )}

          {message.type !== '' && (
            <Alert severity={message.type as any} sx={{ marginBottom: '20px' }}>
              <div dangerouslySetInnerHTML={{ __html: message.content }} />
            </Alert>
          )}

          {company === null && (
            <>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
                <div>
                  <FormControl fullWidth size="small">
                    <TextField
                      name="search"
                      size="small"
                      label="Buscar Empresa"
                      value={search}
                      autoComplete="off"
                      onChange={onChange}
                    />
                  </FormControl>
                </div>

                <div style={{ textAlign: 'right' }}>
                  <Button onClick={() => handleNewCompany()} variant="contained" size="small" sx={{ width: 'auto' }}>
                    Adicionar Empresa &nbsp; <AddCircleOutlineIcon fontSize={'small'} />
                  </Button>
                </div>
              </div>

              {loading && <LinearProgress />}

              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bolder' }}> Empresa </TableCell>
                      <TableCell sx={{ fontWeight: 'bolder' }}> CNPJ </TableCell>
                      <TableCell sx={{ fontWeight: 'bolder' }}> Administrador </TableCell>
                      <TableCell sx={{ fontWeight: 'bolder' }}> Data de Cadastro </TableCell>
                      <TableCell> &nbsp; </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {companies.map((company: any) => (
                      <TableRow key={company.company_id}>
                        <TableCell> {company.company_name} </TableCell>
                        <TableCell> {company.company_cnpj} </TableCell>
                        <TableCell> {company.company_manager_name} </TableCell>
                        <TableCell> {company.company_create} </TableCell>
                        <TableCell>
                          <Fab onClick={() => handleEdit(company)} size="small" color="primary">
                            <EditIcon sx={{ fontSize: 12 }} />
                          </Fab>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Box>
      </Layout>
    </div>
  )
}

export default CompanyManager
