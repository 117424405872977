import {
  Alert,
  Button,
  Fab,
  FormControl,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  TextField,
} from '@mui/material'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import dayjs, { Dayjs } from 'dayjs'
import { FC, useState, forwardRef } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import { IMaskInput } from 'react-imask'
import { request } from '../../utils/fetcher'

const CNPJInputCustom = forwardRef<HTMLInputElement, any>(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props
  return (
    <IMaskInput
      {...other}
      mask="00.000.000/0000-00"
      definitions={{
        '#': /[1-9]/,
      }}
      unmask={true}
      inputRef={ref}
      onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  )
})

const EditComapany: FC<{ data: any; plans: any; handleClose: () => void; handleMessage: any }> = ({ data, plans, handleClose, handleMessage }) => {
  const defaultPost = {
    company_id: data['company_id'],
    max_users: data['company_id'] === 0 ? 10 : data['company_max_users'],
    company_name: data['company_id'] === 0 ? '' : data['company_name'],
    company_cnpj: data['company_id'] === 0 ? '' : data['company_cnpj'],
    company_manager_name: data['company_id'] === 0 ? '' : data['company_manager_name'],
    company_manager_email: data['company_id'] === 0 ? '' : data['company_manager_email'],
    devices:
      data['company_id'] === 0
        ? [
            {
              device_id: 0,
              device_detail: '',
              device_company_plan_id: 0,
              device_server: '',
              device_login: '',
              device_pass: '',
              device_planExpiration: dayjs().unix(),
            },
          ]
        : data['devices'],
  } as any

  const [loading, setLoading] = useState(false)
  const [post, setPost] = useState(defaultPost)
  const [message, setMessage] = useState({ type: '', content: '' })

  const onChange = (e: any) => {
    setMessage({ type: '', content: '' })
    setPost({ ...post, [e.target.name]: e.target.value })
  }

  const onChangeDevice = (index: number, name: string, e: any) => {
    setMessage({ type: '', content: '' })
    setPost({
      ...post,
      ['devices']: post.devices.map((d: any, i: number) => {
        if (i === index) return { ...d, [name]: e.target.value }
        return d
      }),
    })
  }

  const handleRemoveDevice = (index: number) => {
    const d = post.devices.find((d: any, i: number) => i === index)
    
    if (d && d.device_id > 0) {
      request(`company/delete-device/`, { device_id: d.device_id })
    }

    setPost({
      ...post,
      ['devices']: post.devices.filter((d: any, i: number) => i !== index),
    })
  }

  const handleClear = () => {
    setPost(defaultPost as any)
    setMessage({ type: '', content: '' })
  }

  const handleNewsDevice = () => {
    setPost({
      ...post,
      devices: [
        ...post.devices,
        {
          device_id: 0,
          device_detail: '',
          device_company_plan_id: 0,
          device_server: '',
          device_login: '',
          device_pass: '',
          device_planExpiration: dayjs().unix(),
        },
      ],
    })
  }

  const handleSubmit = async () => {
    setMessage({ type: '', content: '' })

    let errors = []

    if (post.company_name === '') errors.push('Nome da Empresa')
    if (post.max_users === '' && post.max_users < 1) errors.push('Máximo de Usuários')
    if (post.company_cnpj === '') errors.push('CNPJ')
    if (post.company_manager_name === '') errors.push('Nome do Administrador')
    if (post.company_manager_mail === '') errors.push('Email do Administrador')
    if (post.devices.length < 1) errors.push('Device')
    
    post.devices.forEach((d: any) => {
      if (d.device_detail === '') errors.push('Número do Device')
      if (d.device_company_plan_id === '' || d.device_company_plan_id === 0) errors.push('Plano do Device')
      if (d.device_server === '') errors.push('Servidor do Device')
      if (d.device_login === '') errors.push('Login do Device')
      if (d.device_pass === '') errors.push('Senha do Device')
      if (d.device_planExpiration === '' || d.device_planExpiration === 0) errors.push('Validade do Device')
    })

    if (errors.length > 0) setMessage({ type: 'error', content: `Preencha os campos: ${errors.join(', ')}.` })
    else {
      setLoading(true)

      const data = await request(`company/save-company/`, { company: post })

      setLoading(false)
      
      handleMessage({ type: data.success ? 'success' : 'error', content: data.message })
      handleClose()
    }
  }

  return (
    <>
      <div style={{ textAlign: 'right' }}>
        <IconButton onClick={() => handleClose()}>
          <CloseIcon />
        </IconButton>
      </div>

      <FormGroup>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FormControl fullWidth size="small">
              <TextField
                name="company_name"
                size="small"
                label="Nome da Empresa*"
                value={post['company_name']}
                autoComplete="off"
                onChange={onChange}
              />
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <FormControl fullWidth size="small">
              <FormControl fullWidth size="small">
                <InputLabel htmlFor="formatted-text-mask-input">CNPJ *</InputLabel>
                <OutlinedInput
                  label="CNPJ *"
                  value={post['company_cnpj']}
                  onChange={onChange}
                  name="company_cnpj"
                  size="small"
                  id="formatted-text-mask-input-cnpj"
                  inputComponent={CNPJInputCustom as any}
                />
              </FormControl>
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <FormControl fullWidth size="small">
              <FormControl fullWidth size="small">
                <TextField
                  name="max_users"
                  size="small"
                  label="Número de Usuários *"
                  value={post['max_users']}
                  autoComplete="off"
                  type="number"
                  onChange={onChange}
                />
              </FormControl>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <div
              style={{
                margin: '32px 0 0 0',
                padding: '32px 16px 16px 16px',
                borderRadius: '8px',
                border: '1px solid #e8e8e8',
                position: 'relative',
              }}
            >
              <h4
                style={{
                  margin: '0 16px 3px 0',
                  fontSize: 16,
                  fontWeight: '700',
                  color: '#000',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  position: 'absolute',
                  marginTop: '-42px',
                }}
              >
                <span style={{ background: '#fff', padding: '0 10px' }}>Administrador</span>
              </h4>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl fullWidth size="small">
                    <TextField
                      name="company_manager_name"
                      size="small"
                      label="Nome *"
                      value={post['company_manager_name']}
                      autoComplete="off"
                      onChange={onChange}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth size="small">
                    <FormControl fullWidth size="small">
                      <TextField
                        name="company_manager_email"
                        type="email"
                        size="small"
                        label="E-mail *"
                        value={post['company_manager_email']}
                        onChange={onChange}
                      />
                    </FormControl>
                  </FormControl>
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid item xs={12}>
            <div
              style={{
                margin: '32px 0 0 0',
                padding: '32px 16px 16px 16px',
                borderRadius: '8px',
                border: '1px solid #e8e8e8',
                position: 'relative',
              }}
            >
              <div style={{ position: 'absolute', marginTop: '-52px', right: '10px' }}>
                <Fab onClick={() => handleNewsDevice()} size="small" color="primary">
                  <AddIcon sx={{ fontSize: 16 }} />
                </Fab>
              </div>

              <h4
                style={{
                  margin: '0 16px 3px 0',
                  fontSize: 16,
                  fontWeight: '700',
                  color: '#000',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  position: 'absolute',
                  marginTop: '-42px',
                }}
              >
                <span style={{ background: '#fff', padding: '0 10px' }}>Devices</span>
              </h4>

              {post['devices'].map((d: any, index: number) => {
                return (
                  <div
                    key={index}
                    style={{ marginBottom: '34px', paddingBottom: '24px', borderBottom: '1px solid #e8e8e8' }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl fullWidth size="small">
                          <TextField
                            name={`device_detail_${index}`}
                            size="small"
                            label="Device *"
                            value={d.device_detail}
                            autoComplete="off"
                            onChange={(e) => onChangeDevice(index, 'device_detail', e)}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={6}>
                        <FormControl fullWidth size="small">
                          <TextField
                            name={`device_server_${index}`}
                            size="small"
                            label={`Servidor *`}
                            value={d.device_server}
                            select
                            onChange={(e) => onChangeDevice(index, 'device_server', e)}
                          >
                            {['z-api'].map((item: any) => (
                              <MenuItem key={item} value={item}>
                                {item}
                              </MenuItem>
                            ))}
                          </TextField>
                        </FormControl>
                      </Grid>

                      <Grid item xs={6}>
                        <FormControl fullWidth size="small">
                          <TextField
                            name={`device_company_plan_id_${index}`}
                            size="small"
                            label={`Plano *`}
                            value={d.device_company_plan_id}
                            select
                            onChange={(e) => onChangeDevice(index, 'device_company_plan_id', e)}
                          >
                            {plans.map((item: any) => (
                              <MenuItem key={item.company_devices_plan_id} value={item.company_devices_plan_id}>
                                {item.company_devices_plan_name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth size="small" sx={{ position: 'relative' }}>
                          <div style={{ position: 'absolute', marginTop: '-8px', width: '100%' }}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
                              <DateTimePicker
                                minDateTime={dayjs()}
                                format="DD/MM/YYYY HH:mm"
                                label="Validade *"
                                value={dayjs.unix(d.device_planExpiration)}
                                componentsProps={{
                                  actionBar: {
                                    actions: ['clear', 'accept'],
                                  },
                                }}
                                slotProps={{
                                  textField: {
                                    size: "small",
                                  },
                                }}
                                onChange={(newValue) => {
                                  if (newValue && newValue <= dayjs()) newValue = dayjs().add(30, 'm')
                                  onChangeDevice(index, 'device_planExpiration', { target: { value: newValue?.unix()} })
                                }}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                          </div>
                        </FormControl>
                      </Grid>

                      <Grid item xs={5}>
                        <FormControl fullWidth size="small">
                          <TextField
                            name={`device_login_${index}`}
                            size="small"
                            label="Login *"
                            value={d.device_login}
                            autoComplete="off"
                            onChange={(e) => onChangeDevice(index, 'device_login', e)}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={5}>
                        <FormControl fullWidth size="small">
                          <TextField
                            name={`device_pass_${index}`}
                            size="small"
                            label="Senha *"
                            value={d.device_pass}
                            autoComplete="off"
                            onChange={(e) => onChangeDevice(index, 'device_pass', e)}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={2} style={{ textAlign: 'right' }}>
                        <Fab onClick={() => handleRemoveDevice(index)} size="small" color="error">
                          <DeleteIcon sx={{ fontSize: 16 }} />
                        </Fab>
                      </Grid>
                    </Grid>
                  </div>
                )
              })}
            </div>
          </Grid>
        </Grid>
      </FormGroup>

      {loading && <LinearProgress />}
      {message.type !== '' && (
        <Alert severity={message.type as any}>
          <div dangerouslySetInnerHTML={{ __html: message.content }} />
        </Alert>
      )}

      <div style={{ margin: '40px 0', paddingBottom: '40px', display: 'flex', justifyContent: 'space-between' }}>
        <Button
          onClick={() => {
            handleClear()
          }}
          size="small"
          sx={{ width: 'auto' }}
        >
          {post['company_id'] > 0 ? 'Restaurar' : 'Limpar'}
        </Button>

        <Button
          onClick={() => {
            handleSubmit()
          }}
          disabled={loading}
          variant="contained"
          size="small"
          sx={{ width: 'auto' }}
        >
          Salvar
        </Button>
      </div>
    </>
  )
}

export default EditComapany
