import { FC, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Chip from '@mui/material/Chip'
import { Box, Button } from '@mui/material'

import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle'
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial'
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import ImageIcon from '@mui/icons-material/Image'
import YardIcon from '@mui/icons-material/Yard'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency'
import MicIcon from '@mui/icons-material/Mic'
import VideocamIcon from '@mui/icons-material/Videocam'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import GroupsIcon from '@mui/icons-material/Groups'

import Loading from '../../Loading'
import { getExtension } from '../../../utils/files'
import { request } from '../../../utils/fetcher'
import StatusLabel from '../../StatusLabel'

const ChatList: FC<{
  seeMoreChats: any
  handleScrollTop: any
  list: any
  selected: any
  loading: boolean
  showFilter: boolean
}> = ({ list, selected, loading, seeMoreChats, handleScrollTop, showFilter }) => {
  const navigate = useNavigate()
  const ref = useRef<HTMLUListElement>(null)

  useEffect(() => {
    ref?.current?.addEventListener('scroll', scrollList)

    return () => {
      ref.current?.removeEventListener('scroll', scrollList)
    }
  }, [list])

  const scrollList = () => {
    if (isOnTop()) handleScrollTop()
  }

  const isOnTop = () => {
    return ref?.current?.scrollTop === 0
  }

  const getNameComponent = (name: string) => {
    return (
      <span
        style={{
          display: 'inline-block',
          width: '60%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {name}
      </span>
    )
  }

  const handleOpenChat = async (data: any) => {
    const { client_id: id, chat_date_start, chat_date_close, chat_employee_id } = data

    if (chat_date_start > 0 && chat_date_close === 0 && chat_employee_id === 0) {
      await request(`messages/${id}/open`)
    }
  }

  const formatter = (texto: string) => {
    var formatacoes = [
      { regex: /\*([^*]+)\*/g, substituto: '<strong>$1</strong>' },
      { regex: /_([^_]+)_/g, substituto: '<em>$1</em>' },
      { regex: /~([^~]+)~/g, substituto: '<del>$1</del>' },
    ]

    for (var i = 0; i < formatacoes.length; i++) {
      var formatacao = formatacoes[i]
      texto = texto.replace(formatacao.regex, formatacao.substituto)
    }

    return texto
  }

  const showLastMessage = (user: string, msg: string, type: any) => {
    let content = <></>

    if (!msg) return <>Nenhuma mensagem carregada.</>

    const complete_message = `${user !== '0' ? `${user}: ` : ''}${msg}`

    if (type === 0)
      return (
        <div
          title={complete_message}
          style={{
            display: 'inline-block',
            width: '100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          dangerouslySetInnerHTML={{ __html: formatter(complete_message) }}
        />
      )

    if (type === 2) {
      content = (
        <>
          <LocationOnIcon sx={{ fontSize: 16 }} /> &nbsp; Localização
        </>
      )
    } else if (type === 3) {
      content = (
        <>
          <ContactEmergencyIcon sx={{ fontSize: 16 }} /> &nbsp; Contato
        </>
      )
    } else if (type === 5) {
      content = (
        <>
          <YardIcon sx={{ fontSize: 16 }} /> &nbsp; Sticker
        </>
      )
    } else {
      switch (getExtension(msg)) {
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'gif':
          content = (
            <>
              <ImageIcon sx={{ fontSize: 16 }} /> &nbsp; Imagem
            </>
          )
          break
        case '3gp':
        case 'mp4':
          content = (
            <>
              <VideocamIcon sx={{ fontSize: 16 }} /> &nbsp; Vídeo
            </>
          )
          break
        case 'acc':
        case 'amr':
        case 'mpeg':
        case 'ogg':
        case 'wav':
        case 'mp3':
        case 'webm':
          content = (
            <>
              <MicIcon sx={{ fontSize: 16 }} /> &nbsp; Audio
            </>
          )
          break
        default:
          content = (
            <>
              <InsertDriveFileIcon sx={{ fontSize: 16 }} /> &nbsp; Documento
            </>
          )
          break
      }
    }

    return <div style={{ display: 'flex', alignItems: 'center' }}>{content}</div>
  }

  const showIcons = (message: any) => {
    const { chat_standby, department_fixed, employee_fixed, schedule_messages_count } = message

    return (
      <span>
        {(employee_fixed && <PersonPinCircleIcon sx={{ width: 14, height: 14 }} />) || null}
        {(department_fixed && <FolderSpecialIcon sx={{ width: 14, height: 14 }} />) || null}
        {(chat_standby && <TransferWithinAStationIcon sx={{ width: 14, height: 14 }} />) || null}
        {(schedule_messages_count && <CalendarMonthIcon sx={{ width: 14, height: 14 }} />) || null}
      </span>
    )
  }

  const heightStyle = showFilter ? 'calc(100dvh - 288px)' : 'calc(100dvh - 34px)'

  if (loading) return <Loading />

  return (
    <List
      ref={ref}
      sx={{
        height: heightStyle,
        overflowY: 'auto',
        padding: 0,
      }}
    >
      {list.map((message: any, i: number) => {
        const checked = `${selected}` === `${message.client_id}`
        return (
          <ListItemButton
            key={i}
            selected={checked}
            onClick={() => {
              handleOpenChat(message)
              navigate(`/chat/${message.client_id}`)
            }}
          >
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', gap: '5px' }}>
              <div style={{ display: 'flex', width: '75%', alignItems: 'center' }}>
                <ListItemText
                  primary={
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                      <StatusLabel data={message} />

                      {message.isGroup === 1 && (
                        <>
                          <span title="Grupo" style={{ display: 'flex' }}>
                            <GroupsIcon color="primary" sx={{ fontSize: 16 }} />
                          </span>
                          &nbsp;
                        </>
                      )}
                      {getNameComponent(message.exhibition_name)}
                    </div>
                  }
                  primaryTypographyProps={{ fontSize: '12px', lineHeight: '24px' }}
                  secondary={showLastMessage(
                    message.last_message_user,
                    message.last_message,
                    message.last_message_type
                  )}
                  secondaryTypographyProps={{ fontSize: '10px' }}
                />
              </div>
              <div>
                <ListItemText
                  primary={
                    ((message.count > 0 || message.chat_mark_unread > 0) && (
                      <Chip
                        label={message.count > 0 ? message.count : ''}
                        color={message.count > 0 ? 'success' : 'warning'}
                        size="small"
                        sx={{
                          minWidth: 18,
                          height: 18,
                          fontSize: 9,
                          '& span': {
                            padding: '0 4px',
                          },
                        }}
                      />
                    )) ||
                    (message.count <= 0 && message.chat_mark_unread <= 0 && (
                      <div style={{ display: 'block', height: '24px' }}> &nbsp; </div>
                    ))
                  }
                  primaryTypographyProps={{ textAlign: 'right' }}
                  secondary={
                    <>
                      <div>{message.last_time}</div>
                      {showIcons(message)}
                    </>
                  }
                  secondaryTypographyProps={{ fontSize: '10px', textAlign: 'right' }}
                />
              </div>
            </div>
          </ListItemButton>
        )
      })}
      {list.length > 0 && (
        <Box sx={{ textAlign: 'center', margin: '20px 0 40px 0' }}>
          <Button onClick={() => seeMoreChats()}>Ver Mais +</Button>
        </Box>
      )}
    </List>
  )
}

export default ChatList
